<template>
  <footer class="w-full max-w-5xl m-auto px-2 pb-4">
    <div
      class="border-t-2 border-black mb-4 pt-4 flex gap-6 flex-col md:flex-row md:justify-between md:items-center">
      <div class="flex justify-between sm:justify-start">
        <img
          class="mr-6 logo"
          alt="Logo Schleswig-Holstein Ministerium für Bildung, Wissenschaft und Kultur"
          src="../../assets/img/sh-ministerium.svg" />
        <img
          class="mr-6 logo"
          alt="Logo Filmförderung Hamburg Schleswig-Holstein"
          src="../../assets/img/FFHSH.svg" />
        <img
          class="logo"
          alt="Logo Arthur-Boskamp Stiftung"
          src="../../assets/img/arthur-boskamp-stiftung.svg" />
      </div>

      <nav class="font-worksans">
        <ul class="flex justify-between sm:justify-end items-center gap-4 m-0 p-0">
          <li>
            <a href="http://kaiehlers.de" target="_blank">
              <img
                class="logo-kai"
                alt="Logo Kai Ehlers Fil"
                src="../../assets/img/kai-ehlers-film.svg" />
            </a>
          </li>
          <li>
            <router-link to="/Datenschutz">Datenschutz</router-link>
          </li>
          <li>
            <router-link to="/impressum">Impressum</router-link>
          </li>
        </ul>
      </nav>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter'
};
</script>

<style scoped lang="scss">
ul {
  a {
    border-bottom: 2px solid transparent;

    &:hover {
      border-bottom: 2px solid black;
    }
  }
  .router-link-exact-active {
    border-bottom: 2px solid black;
  }
}
.logo {
  height: 30px;
}
.logo-kai {
  margin-top: 2px;
  height: 9px;
}
@media (min-width: 768px) {
  .logo {
    height: 50px;
  }
  .logo-kai {
    margin-top: 0;
    height: 12px;
  }
}
</style>
