<template>
  <header class="header-position">
    <NavigationMobile class="md:hidden" />
    <NavigationDesktop class="hidden md:block" />
    <Audioplayer />
  </header>
</template>

<script>
import NavigationDesktop from '@/components/NavigationDesktop.vue';
import NavigationMobile from '@/components/NavigationMobile.vue';
import Audioplayer from '@/components/Audioplayer.vue';

export default {
  name: 'AppHeader',
  components: { NavigationDesktop, NavigationMobile, Audioplayer }
};
</script>

<style scoped lang="scss">
.header-position {
  position: fixed;
  z-index: 50;
  top: 0;
  width: 100%;
}
</style>
