<template>
  <div v-if="loaded" class="home font-neuton mb-6">
    <h1 class="text-2xl mt-2 mb-4" v-html="pageContent.content"></h1>
    <div v-for="komplex in komplexe" :key="komplex.title">
      <router-link :to="'/kapitel/' + komplex.value">
        <h2 class="card text-xl mb-2">
          {{ komplex.title }}
        </h2>
      </router-link>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex';

export default {
  name: 'Home',
  metaInfo: {
    title: 'Kapitelübersicht'
  },
  components: {},
  data() {
    return {};
  },
  created() {
    this.$store.dispatch('getThemenkomplexe');
  },
  computed: {
    ...mapGetters({
      komplexe: 'getAllThemenkomplexe',
      getPageById: 'getPageById',
      loadedKomplexe: 'getThemenkomplexeLoaded',
      loadedPages: 'getPagesLoaded'
    }),
    pageContent() {
      return this.getPageById(27);
    },
    loaded() {
      return this.loadedPages && this.loadedKomplexe;
    }
  }
};
</script>

<style scoped lang="scss">
.card {
  @apply p-4 shadow;
  background-color: lighten($background, 10%);
  transition: all 0.5s ease-in-out;
  &:hover {
    transition: all 0.5s ease-in-out;
    background-color: $background;
  }
}
</style>
