import Api from '@/api/api';

const state = {
  posts: [],
  postsLoaded: false
};

const getters = {
  getAllPosts: state => state.posts,
  getPostsByKomplex: state => komplex => {
    return state.posts.filter(post => post.themenkomplex === komplex);
  },
  getPostsLoaded: state => state.postsLoaded
};

const actions = {
  async getPostsByKomplex({ commit }, { komplex }) {
    commit('setPostsLoaded', false);
    const response = await Api().get(`fsa/v1/posts/${komplex}`);
    const posts = response.data;
    commit('setPosts', posts);
    commit('setPostsLoaded', true);
  }
};

const mutations = {
  setPosts: (state, posts) => {
    posts.forEach(post => {
      if (!state.posts.find(({ id }) => id === post.id)) {
        state.posts.push(post);
      }
    });
  },
  setPostsLoaded: (state, status) => {
    state.postsLoaded = status;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
