<template>
  <div class="mobile-wrapper">
    <div class="mobile-box">
      <svg
        class="mobile-open w-8 h-8"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        @click="open = true"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 6h16M4 12h16M4 18h16"
        />
      </svg>
      <div class="logo-title font-neuton text-2xl">Freistaat Mittelpunkt</div>
      <div class="line border border-black w-full"></div>
    </div>
    <div :class="{ menuopen: open }" class="menu-container flex flex-col justify-center">
      <svg
        class="w-8 h-8 mobile-close"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        @click="open = false"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
      <div class="flex flex-col justify-start router-links">
        <router-link to="/" @click.native="open = false">Archiv</router-link>
        <router-link to="/vorwort" @click.native="open = false">Vorwort</router-link>
        <router-link to="/hintergrund" @click.native="open = false">Hintergrund</router-link>
        <router-link to="/dank" @click.native="open = false">Dank</router-link>
        <router-link to="/film" @click.native="open = false">Film</router-link>
        <router-link to="/spenden" @click.native="open = false">Spenden</router-link>
        <router-link to="/datenschutz" @click.native="open = false">Datenschutz</router-link>
        <router-link to="/impressum" @click.native="open = false">Impressum</router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      open: false
    };
  }
};
</script>
<style scoped lang="scss">
.mobile-wrapper {
  height: 4em;
  background-color: lighten($background, 20%);
}
.mobile-box {
  position: relative;
}
.mobile-open {
  position: absolute;
  top: 1em;
  left: 1em;
}
.logo-title {
  position: absolute;
  top: 0.5em;
  right: 1em;
}
.line {
  position: relative;
  top: 4em;
}
.mobile-close {
  position: absolute;
  top: 1em;
  right: 1em;
}
.menu-container {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: width 0.5s ease-out;
  background-color: lighten($background, 10%);
}
.menuopen {
  width: 100%;
}
.router-links a {
  font-family: 'Worksans';
  font-weight: 400;
  font-size: 1.2em;
  line-height: 3em;
  height: 3em;
  align-self: center;
  text-align: center;
  width: 100%;
  border-bottom: 1px solid lighten($background, 20%);
  border-top: 1px solid lighten($background, 20%);
  margin-bottom: 0.7em;
  &:last-child {
    margin-bottom: 0;
  }
}
</style>
