import Vue from 'vue';
import Vuex from 'vuex';
import themenkomplexe from '@/store/modules/themenkomplexe';
import posts from '@/store/modules/posts';
import soundcloud from '@/store/modules/soundcloud';
import pages from '@/store/modules/pages';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    themenkomplexe,
    posts,
    soundcloud,
    pages
  }
});
