import Api from '@/api/api';

const state = {
  soundcloud: '',
  soundcloudLoaded: false
};

const getters = {
  getSoundcloudCode: state => state.soundcloud,
  getSoundcloudLoaded: state => state.soundcloudLoaded
};

const actions = {
  async getSoundcloud({ commit }) {
    commit('setSoundcloudLoaded', false);
    const response = await Api().get('acf/v3/options/options/soundcloud_code');
    const soundcloud = response.data.soundcloud_code;
    commit('setSoundcloud', soundcloud);
    commit('setSoundcloudLoaded', true);
  }
};

const mutations = {
  setSoundcloud: (state, soundcloud) => {
    state.soundcloud = soundcloud;
  },
  setSoundcloudLoaded: (state, status) => {
    state.soundcloudLoaded = status;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
