<template>
  <div class="max-w-5xl m-auto px-2">
    <div
      v-if="loaded"
      class=""
      :class="[open ? 'player-open' : 'player-closed']"
      v-html="soundcloud"
    ></div>

    <button
      class="
        audio-player
        py-2
        px-4
        border-l-2 border-r-2 border-b-2 border-black
        font-worksans
      "
      @click="open = !open"
      v-text="open ? 'Audioplayer schließen' : 'Audioplayer öffnen'"
    ></button>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Audioplayer',
  components: {},
  data() {
    return {
      open: false
    };
  },
  computed: {
    ...mapGetters({
      soundcloud: 'getSoundcloudCode',
      loaded: 'getSoundcloudLoaded'
    })
  },
  beforeCreate() {
    this.$store.dispatch('getSoundcloud');
  }
};
</script>
<style scoped lang="scss">
button:focus {
  outline: none;
}
.audio-player {
  background-color: lighten($background, 20%);
}
.player-open {
  display: block;
  height: auto;
  @apply border-l-2 border-r-2 border-b-2 border-black;
}
.player-closed {
  height: 0;
  display: none;
}
</style>
