import Api from '@/api/api';

const state = {
  pages: [],
  pagesLoaded: false
};

const getters = {
  getAllPages: state => state.pages,
  getPagesLoaded: state => state.pagesLoaded,
  getPageById: state => id => {
    return state.pages.find(page => page.id === id);
  }
};

const actions = {
  async getPages({ commit }) {
    commit('setPagesLoaded', false);
    const response = await Api().get('acf/v3/pages');
    const pages = response.data.map(item => {
      return {
        id: item.id,
        content: item.acf.content
      };
    });
    commit('setPages', pages);
    commit('setPagesLoaded', true);
  }
};

const mutations = {
  setPages: (state, pages) => {
    state.pages = pages;
  },
  setPagesLoaded: (state, status) => {
    state.pagesLoaded = status;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
