<template>
  <div id="app" class="">
    <AppHeader />
    <div class="router-content w-full max-w-5xl mx-auto mt-28 px-2">
      <transition name="view">
        <router-view />
      </transition>
    </div>
    <AppFooter class="footer-sticky" />
  </div>
</template>

<script>
import AppHeader from '@/components/partials/AppHeader.vue';
import AppFooter from '@/components/partials/AppFooter.vue';

export default {
  name: 'App',
  metaInfo: {
    title: 'Online Archiv',
    titleTemplate: '%s | Freistaat Mittelpunkt Archiv',
    meta: [
      {
        name: 'description',
        content:
          'Das Recherchearchiv zum Dokumentarfilm Freistaat Mittelpunkt über Ernst Otto Karl Grassmé. Ein Projekt von Kai Ehlers.'
      }
    ]
  },
  components: {
    AppHeader,
    AppFooter
  },
  created() {
    this.$store.dispatch('getPages');
  }
};
</script>

<style lang="scss">
html {
  overflow-y: scroll;
}
body {
  background-color: lighten($background, 20%);
}
// router transitions
.view-enter-active,
.view-leave-active {
  transition: opacity 0.5s ease-in-out, transform 0.5s ease;
}
.view-enter-active {
  transition-delay: 0.5s;
}
.view-enter,
.view-leave-to {
  opacity: 0;
  /* transform: translateY(-100px); */
}
.view-enter-to,
.view-leave {
  opacity: 1;
  /* transform: translateY(0px); */
}
// sticky footer

#app,
html,
body {
  height: 100%;
}
#app {
  display: flex;
  flex-direction: column;
}
.router-content {
  flex: 1 0 auto;
}
.footer-sticky {
  flex-shrink: 0;
}
.button {
  transition: all 0.5s ease-in-out;
  @apply border-2 border-black font-worksans text-base px-2 py-1 inline-block;
  &:hover {
    transition: all 0.5s ease-in-out;
    background-color: $background;
  }
}
.content-page {
  background-color: lighten($background, 10%);
  @apply font-worksans text-base p-4 my-4 shadow;
}
.content {
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
</style>
