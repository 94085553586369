const options = {
  root: null,
  treshold: 0.5
};

const animatedScrollObserver = new IntersectionObserver((entries, animatedScrollObserver) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      entry.target.classList.add('enter');
      animatedScrollObserver.unobserve(entry.target);
    }
  });
}, options);

export default {
  bind(el) {
    el.classList.add('before-enter');
    animatedScrollObserver.observe(el);
  }
};
