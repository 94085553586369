import Api from '@/api/api';

const state = {
  themenkomplexe: [],
  themenkomplexeLoaded: false
};

const getters = {
  getAllThemenkomplexe: state => state.themenkomplexe,
  getThemenkomplexeLoaded: state => state.themenkomplexeLoaded
};

const actions = {
  async getThemenkomplexe({ commit }) {
    commit('setThemenkomplexeLoaded', false);
    const response = await Api().get('acf/v3/options/options/archiv_themenkomplexe');
    const themenkomplexe = response.data.archiv_themenkomplexe.map(item => {
      return {
        title: item.archiv_themenkomplex,
        value: item.archiv_themenkomplex_name
      };
    });
    commit('setThemenkomplexe', themenkomplexe);
    commit('setThemenkomplexeLoaded', true);
  }
};

const mutations = {
  setThemenkomplexe: (state, themenkomplexe) => {
    state.themenkomplexe = themenkomplexe;
  },
  setThemenkomplexeLoaded: (state, status) => {
    state.themenkomplexeLoaded = status;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
